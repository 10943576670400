/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Button, Alert } from 'react-bootstrap';
import './style.scss';
import { useStores } from 'services/useStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ROUTES } from './App';
// eslint-disable-next-line import/no-relative-packages
import locale from '../../commands_ua';

const Settings: React.FC = observer(() => {
  const history = useNavigate();
  const { api, sessionStore } = useStores();
  const [text, setText] = useState(JSON.stringify(api.settings.app));
  const [error, setError] = useState<string>('');
  const backButton = api.telegram.tg.BackButton;
  const save = () => {
    if (error) return;
    api.setSettings(text).then(() => {
      api.settings.app = JSON.parse(text);
      history(ROUTES.home);
    });
  };
  const updateText = (value: string) => {
    setText(value);
    setError('');
    try {
      JSON.parse(value);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setError(e.message);
    }
  };
  useEffect(() => {
    if (sessionStore.session.user_role !== 'manager') history(ROUTES.home);
    backButton.show();
    backButton.onClick(() => {
      history(ROUTES.home);
    });
  }, []);

  return (
    <Container className='editable'>
      {error ? <Alert variant='danger'>{error}</Alert> : ''}
      <textarea value={text} onChange={(e) => updateText(e.target.value)} />
      <Button onClick={save} disabled={!!error}>
        {locale.frontEnd.global.save}
      </Button>
    </Container>
  );
});
export default Settings;
