/* eslint-disable class-methods-use-this */
import { TBook } from 'components/App/Booking';
import { TOrderItem } from 'stores/BookingStore';
import moment from 'moment';
import { TUseTelegram, useTelegram } from './useTelegram';
import { RootStore } from '../stores/RootStore';
import 'moment/locale/uk';

export type TAPISettingsAPP = {
  [key: string]: string | number | boolean;
};

export type TAPISettingsURL = {
  [key: string]: string;
};

type TAPISettings = {
  url: TAPISettingsURL;
  app: TAPISettingsAPP;
};

export default class Api {
  stores: RootStore;
  access: boolean = false;
  firstRun: boolean = false;
  telegram!: TUseTelegram;
  settings: TAPISettings = {
    url: {
      server: 'https://soloway.larp.tools/api/',
      register: 'registration',
      orders: 'orders',
      userInfo: 'userInfo',
      booking: 'booking',
      settings: 'settings',
    },
    app: {},
  };

  constructor(stores: RootStore) {
    this.stores = stores;
    moment.locale('uk');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.telegram = useTelegram() as TUseTelegram;
    document.getElementsByTagName('body')[0]?.setAttribute('class', 'bot');
    document.documentElement.style.setProperty(
      '--tg-bg-opacity',
      this.telegram.tg.themeParams.bg_color + 'dd'
    );
    console.log('AS TELEGRAM BOT');
  }
  getLocalConfig = (name: string) => window.localStorage[name];
  setLocalConfig = (name: string, value: string | number | boolean) => {
    window.localStorage[name] = value;
  };

  getData = async (url: string, options: object = {}, data?: object) => {
    const config: RequestInit = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Token': (this?.telegram?.user?.id || 185773873) + '',
      },
      ...options,
    };
    if (data) config.body = JSON.stringify(data);
    try {
      const response = await fetch(this.settings.url.server + url, config);
      if (!response.ok) console.warn('Get data', url, response);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.warn(e.message, this.settings.url.server + url);
      return false;
    }
  };

  getJSON = async (url: string, options: object = {}, data?: object) => {
    const response = await this.getData(
      url,
      {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        ...options,
      },
      data
    );
    if (typeof response === 'object') {
      try {
        const returnObject = await response.json();
        return returnObject;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.warn(url, e.message);
      }
    }
    return {};
  };

  changeAccess = (newAccess: boolean) => {
    this.access = newAccess;
  };

  changeFirstRun = (value: boolean) => {
    this.firstRun = value;
  };

  getSettings = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const url = this.settings.url.settings + '/settings';
      this.getJSON(url).then((data) => {
        if (data.result) {
          this.settings.app = JSON.parse(data.result.replaceAll("'", '"'));
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  getRules = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const url = this.settings.url.settings + '/help';
      this.getJSON(url).then((data) => {
        if (data.result) {
          resolve(data.result);
        } else {
          resolve('шось пішло не так');
        }
      });
    });
  };

  setRules = (text: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const url = this.settings.url.settings + '/help';
      this.getJSON(url, { method: 'POST' }, { value: text })
        .then((value) => {
          resolve(value);
        })
        .catch((e) => reject(e));
    });
  };

  setSettings = (text: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const url = this.settings.url.settings + '/settings';
      this.getJSON(url, { method: 'POST' }, { value: text })
        .then((value) => {
          resolve(value);
        })
        .catch((e) => reject(e));
    });
  };

  getSession = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const url = this.settings.url.userInfo;
      this.getJSON(url).then((data) => {
        if (data.result) {
          this.stores.sessionStore.update(data.result);
          this.changeAccess(true);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerTelegram = (data: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.register, { method: 'POST' }, data)
        .then((value) => {
          resolve(value.result);
        })
        .catch((e) => reject(e));
    });
  };

  booking = (data: TBook): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.booking, { method: 'POST' }, data)
        .then((value) => {
          resolve(value.result);
        })
        .catch((e) => reject(e));
    });
  };

  removeOrder = (orderId: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.booking + '/' + orderId, { method: 'DELETE' })
        .then((value) => {
          resolve(value.result);
        })
        .catch((e) => reject(e));
    });
  };

  getOrders = (date: string) => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.booking + '/' + date)
        .then((data) => {
          if (data?.result) {
            this.stores.booking.update(data?.result);
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };
  getAllOrders = (): Promise<TOrderItem[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.booking)
        .then((data) => {
          resolve(data?.result as TOrderItem[]);
        })
        .catch((e) => reject(e));
    });
  };
}
