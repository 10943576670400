import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Form, Button, Spinner } from 'react-bootstrap';
import './style.scss';
import { TUseTelegram, useTelegram } from 'services/useTelegram';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useStores } from 'services/useStore';
import { TOrderItem } from 'stores/BookingStore';
import { observer } from 'mobx-react';
import { ROUTES } from './App';
// eslint-disable-next-line import/no-relative-packages
import locale from '../../commands_ua';

type TPlace = number[];
type TOrderMap = TPlace[];
type TOrdered = { PC: number; start: number; end: number };
export type TBook = {
  user_id: number;
  user_tg: number;
  date: string;
  orderList: TOrdered[];
};

const Booking: React.FC = observer(() => {
  const { api, sessionStore, booking } = useStores();
  const history = useNavigate();
  const telegram = useTelegram() as TUseTelegram;
  const [state, setState] = useState<string>();
  const [loaded, setLoaded] = useState(false);
  const [startTime, setStartTime] = useState<number>(10);
  const [endTime, setEndTime] = useState<number>(18);
  const [orderMap, setOrderMap] = useState<TOrderMap>();
  const [orderedList, setOrderedList] = useState<TOrdered[]>();
  //const [orderDate, setOrderDate] = useState<moment.Moment>();
  const [orderDate, setOrderDate] = useState<string>();
  const [pc, setPC] = useState<string>('');
  const mainButton = telegram.tg.MainButton;
  const backButton = telegram.tg.BackButton;
  const PCcount = +api.settings.app.PCcount || 5;
  const { workTime, studentDay } = api.settings.app;

  const clearData = () => {
    const orderMapCopy = [];
    const line = [];
    for (let i = startTime; i < endTime + 1; i++) line.push(0);
    for (let j = 1; j < PCcount + 1; j++) orderMapCopy.push([...line]);
    //orderMapCopy[1][1] = 2; ///
    return orderMapCopy;
  };
  const orderStep = () => {
    mainButton.hide();
    setState('checking');
  };

  const sendData = () => {
    if (!orderDate) return;
    api
      .booking({
        user_id: sessionStore.session.user_id || 0,
        user_tg: api.telegram.user.id,
        date: orderDate,
        orderList: orderedList || [],
      })
      .then((result: boolean) => {
        setState('result');
      });
  };

  const buildBysy = (date: string) => {
    const clear = clearData();
    const currentOrderMap = [...clear];
    booking.data.forEach((order: TOrderItem) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { user_id, order_date, order_pc, order_start, order_end } = order;
      if (order_date !== date) return;
      const during = order_end - order_start;
      for (let i = 0; i < during; i++) {
        currentOrderMap[order_pc - 1][order_start + i - startTime] =
          user_id === sessionStore.session.user_id ? 3 : 2;
      }
    });
    setOrderMap(currentOrderMap);
  };

  const checkBysy = async () => {
    if (!orderDate) {
      return;
    }
    api.getOrders(orderDate).then(() => {
      if (orderDate) buildBysy(orderDate);
      setLoaded(true);
    });
  };

  const setDate = (date: string) => {
    let newDate = date;
    const weekDay = moment(newDate).isoWeekday();
    const [start, end] = workTime[weekDay - 1];
    const userStatus =
      typeof sessionStore.session.user_data === 'string'
        ? JSON.parse(sessionStore.session.user_data.replaceAll("'", '"'))?.status
        : 'isCivil';
    if (moment() > moment(date) && sessionStore.session.user_role === 'user')
      newDate = moment().format('YYYY-MM-DD');
    if (weekDay === studentDay && userStatus !== 'isStudent') {
      setStartTime(1);
      setEndTime(1);
    } else {
      setStartTime(+start);
      setEndTime(+end);
    }
    setOrderDate(newDate);
  };

  useEffect(() => {
    setState('booking');
    setOrderDate(moment().format('YYYY-MM-DD') + '');
    setDate(moment().format('YYYY-MM-DD') + '');
    const pcList = [];
    for (let i = 1; i < PCcount + 1; i++) pcList.push(i);
    setPC(pcList.join(','));
    backButton.show();
    backButton.onClick(() => {
      history(ROUTES.home);
    });
  }, []);

  useEffect(() => {
    setLoaded(false);
    checkBysy();
  }, [orderDate]);

  useEffect(() => {
    switch (state) {
      case 'booking':
        mainButton.setText(locale.frontEnd.global.booking);
        if (orderedList?.length) mainButton.show();
        else mainButton.hide();
        mainButton.onClick(orderStep);
        break;
      default:
        mainButton.hide();
        break;
    }
  }, [state]);

  useEffect(() => {
    if (state !== 'booking' || !orderMap) return;
    const ordered = [];
    for (let i = 1; i < PCcount + 1; i++) {
      let orderThis = { PC: i, start: 0, end: 0 };
      for (let h = startTime; h < endTime + 2; h++) {
        if (orderMap[i - 1][h - startTime] === 1) {
          if (!orderThis.start) {
            orderThis.start = h;
          } else orderThis.end = h;
        } else if (orderThis.start) {
          orderThis.end = h;
          ordered.push({ ...orderThis });
          orderThis = { PC: i, start: 0, end: 0 };
        }
      }
    }
    if (!ordered.length) mainButton.hide();
    else {
      mainButton.show();
      setOrderedList([...ordered]);
    }
  }, [orderMap]);

  const order = (time: number, place: number) => {
    if (!orderMap) return;
    if (orderMap[place - 1][time - startTime] > 1) return;
    if (moment() > moment(`${orderDate} ${time + startTime}:00`)) {
      // eslint-disable-next-line no-alert
      window.alert(locale.frontEnd.global.bookingPrevAlert);
      return;
    }
    const newOrderMap = [...orderMap];
    newOrderMap[place - 1][time - startTime] = newOrderMap[place - 1][time - startTime] ? 0 : 1;
    setOrderMap(newOrderMap);
  };
  const buttonClassName = (time: number, place: number) => {
    if (!orderMap) return '';
    switch (orderMap[place - 1][time - startTime]) {
      case 1:
        return 'active';
      case 2:
        return 'bysy';
      case 3:
        return 'ordered';
      default:
        return '';
    }
  };

  const build = () => {
    const rows = [];
    if (!startTime && !endTime) {
      return <h3 style={{ textAlign: 'center' }}>{locale.frontEnd.global.weekendDay}</h3>;
    }
    if (startTime === 1 && endTime === 1) {
      return <h3 style={{ textAlign: 'center' }}>{locale.frontEnd.global.nauDay}</h3>;
    }
    for (let i = startTime; i < endTime + 1; i++) {
      const cols = [
        <div className='time-header' key={'timeHeader' + i}>
          {i < 10 ? 0 : ''}
          {i}.00
        </div>,
      ];
      for (let j = 1; j < PCcount + 1; j++) {
        cols.push(
          <Button key={`${j}${i}`} className={buttonClassName(i, j)} onClick={() => order(i, j)}>
            {i < 10 ? 0 : ''}
            {i}.00
            <br />
            {i + 1 < 10 ? 0 : ''}
            {i + 1}.00
          </Button>
        );
      }
      rows.push(cols);
    }
    return rows.map((i, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className='order-line'>
        {i}
      </div>
    ));
  };
  const back = () => {
    setState('booking');
  };
  if (!loaded) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: '50% auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
        </div>
      </div>
    );
  }
  if (state === 'booking')
    return (
      <Container className='mt-2'>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4>{locale.frontEnd.global.selectDate}</h4>
            <Form.Control
              value={orderDate}
              onChange={(e) => setDate(e.target.value)}
              type='date'
              required
              size='sm'
              style={{ width: '50%' }}
            />
          </Col>
        </Row>
        {orderDate ? (
          <>
            <Row>
              <Col>
                <h4>{locale.frontEnd.global.selectPCAndTime}</h4>
                <div className='example-line'>
                  <span className='example-container'>
                    <span className='example' />
                    {locale.frontEnd.orderStatus.free}
                  </span>
                  <span className='example-container'>
                    <span className='example' style={{ backgroundColor: 'red' }} />
                    {locale.frontEnd.orderStatus.bysy}
                  </span>
                </div>
                <div className='example-line'>
                  <span className='example-container'>
                    <span className='example' style={{ backgroundColor: 'green' }} />
                    {locale.frontEnd.orderStatus.selected}
                  </span>
                  <span className='example-container'>
                    <span className='example' style={{ backgroundColor: 'blue' }} />
                    {locale.frontEnd.orderStatus.your}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='time'>
                <div className='time-body'>
                  <div className='header'>
                    <div className='empty-header'>
                      №<br />
                      {locale.frontEnd.global.pc2}
                    </div>
                    {pc.split(',').map((i) => (
                      <div className='place-header' key={'place' + i}>
                        🖥 {i}
                      </div>
                    ))}
                  </div>
                  {build()}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}
      </Container>
    );
  if (state === 'checking')
    return (
      <Container className='center-container'>
        <div style={{ border: '1px solid gray', padding: 10, borderRadius: 10 }}>
          <Row>
            <Col>
              <h4>{locale.frontEnd.global.checkYourOrder}</h4>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>{locale.frontEnd.global.date}</b> {moment(orderDate)?.format('Do MMMM, dddd')}
            </Col>
          </Row>
          {orderedList?.map((orderItem: TOrdered) => (
            <Row key={`${orderItem.PC}${orderItem.start}${orderItem.end}`}>
              <Col>
                <b>
                  {locale.frontEnd.global.pc1}
                  {orderItem.PC}
                </b>
                : {orderItem.start}.00 - {orderItem.end}.00
              </Col>
            </Row>
          ))}

          <Row className='mt-3'>
            <Col style={{ textAlign: 'right' }}>
              <Button onClick={back}>{locale.frontEnd.global.edit}</Button>{' '}
              <Button onClick={sendData}>{locale.frontEnd.global.approve}</Button>
            </Col>
          </Row>
        </div>
      </Container>
    );
  if (state === 'result')
    return (
      <Container className='center-container'>
        <div style={{ border: '1px solid gray', padding: 10, borderRadius: 10 }}>
          <Row>
            <Col>
              <h1>{locale.frontEnd.global.orderSaved}</h1>
            </Col>
          </Row>
        </div>
      </Container>
    );
  return <></>;
});
export default Booking;
