import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export type TUser = {
  user_id?: number;
  user_name: string;
  user_email: string;
  user_phone: string;
  user_tg: string;
  user_tg_name: string;
  user_role?: string;
  user_data?: string;
  user_parsed_data?: { [key: string]: string | number | boolean };
};

export type TAppSettings = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export interface ISessionStore {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  session: TUser;
  appSettings: TAppSettings;
  update: (data: TUser) => void;
}

class SessionStore implements ISessionStore {
  session!: TUser;
  appSettings!: TAppSettings;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.appSettings = {} as TAppSettings;

    makeObservable(this, {
      session: observable,
      appSettings: observable,
      update: action,
    });
  }

  update = (data: TUser): void => {
    let newData = { ...data };
    try {
      const extData = newData.user_data ? JSON.parse(newData.user_data) : {};
      newData = { ...newData, user_parsed_data: extData };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.warn('Error in user data', newData.user_data, e.message);
      newData = { ...newData, user_parsed_data: {} };
    }
    this.session = newData;
  };
}

export default SessionStore;
