import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Form, Alert, Button } from 'react-bootstrap';
import './style.scss';
import { TUseTelegram, useTelegram } from 'services/useTelegram';
import { useStores } from 'services/useStore';
import { TUser } from 'stores/SessionStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ROUTES } from './App';
// eslint-disable-next-line import/no-relative-packages
import locale from '../../commands_ua';

const Registration: React.FC = observer(() => {
  const history = useNavigate();
  const { api, sessionStore } = useStores();
  const telegram = useTelegram() as TUseTelegram;
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [id, setId] = useState(0);
  const [mute, setMute] = useState(0);
  const [emailUsed, setEmailUsed] = useState(false);
  const [validated, setValidated] = useState(false);
  const [status, setStatus] = useState('isCivil');
  const [experience, setExperience] = useState('none');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const mainButton = telegram.tg.MainButton;
  const handleProfile = async () => {
    const form = document.getElementById('registerForm') as HTMLFormElement;
    setEmailUsed(false);
    setEmailInvalid(false);
    if (form.checkValidity()) {
      setValidated(true);
      const data: TUser = {
        user_email: email,
        user_name: name,
        user_phone: phone,
        user_tg_name: telegram.user.username || telegram.user.first_name,
        user_tg: telegram.user.id + '',
        user_data: JSON.stringify({ status, experience, mute }),
      };
      if (id) data.user_id = id;
      const result = await api.registerTelegram(data);
      if (result?.errors) {
        setEmailUsed(true);
      } else {
        window.location.href = ROUTES.home;
      }
    } else {
      setEmailInvalid(true);
      setValidated(false);
    }
  };
  useEffect(() => {
    api.telegram.tg.BackButton.hide();
    mainButton.hide();
    if (sessionStore.session?.user_id) {
      setId(sessionStore.session?.user_id);
      setEmail(sessionStore.session?.user_email);
      setName(sessionStore.session?.user_name);
      setPhone(sessionStore.session?.user_phone);
      const userStatus =
        typeof sessionStore.session.user_data === 'string'
          ? JSON.parse(sessionStore.session.user_data.replaceAll("'", '"'))
          : {};
      setStatus(userStatus.status);
      setMute(userStatus.mute || 0);
      setExperience(userStatus.experience);
      api.telegram.tg.BackButton.show();
      api.telegram.tg.BackButton.onClick(() => {
        history(ROUTES.home);
      });
    }
  }, []);

  return (
    <Container className='center-container'>
      <Form action='#' id='registerForm' validated={validated}>
        <Row className='mb-3'>
          <Col>
            <h3 style={{ textAlign: 'center' }}>
              {id ? locale.frontEnd.registration.header2 : locale.frontEnd.registration.header}
            </h3>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Form.Control
              type='email'
              required
              size='sm'
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={locale.frontEnd.registration.email}
              isInvalid={!!emailInvalid}
            />
          </Col>
        </Row>
        {emailUsed ? (
          <Row>
            <Col>
              <Alert variant='danger'>{locale.frontEnd.registration.emailBysy}</Alert>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row className='mb-3'>
          <Col>
            <Form.Control
              type='text'
              required
              size='sm'
              value={phone || ''}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={locale.frontEnd.registration.phone}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Form.Control
              type='text'
              required
              size='sm'
              value={name || ''}
              onChange={(e) => setName(e.target.value)}
              placeholder={locale.frontEnd.registration.name}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>{locale.frontEnd.registration.experience.title}</Col>
        </Row>
        <Row className='mb-3'>
          <Col>
            <Form.Select
              aria-label=''
              required
              size='lg'
              value={experience}
              onChange={(e) => setExperience(e.currentTarget.value)}
            >
              <option key='none' value='none'>
                {locale.frontEnd.registration.experience.none}
              </option>
              <option key='simulator' value='simulator'>
                {locale.frontEnd.registration.experience.simulator}
              </option>
              <option key='dron' value='dron'>
                {locale.frontEnd.registration.experience.dron}
              </option>
            </Form.Select>
          </Col>
        </Row>
        <Row className='mb-3'>
          <Col>{locale.frontEnd.registration.statuses.title}</Col>
        </Row>
        <Row>
          <Col>
            <Form.Select
              aria-label=''
              required
              size='lg'
              value={status}
              onChange={(e) => setStatus(e.currentTarget.value)}
            >
              <option key='isCivil' value='isCivil'>
                {locale.frontEnd.registration.statuses.isCivil}
              </option>
              <option key='isMilitary' value='isMilitary'>
                {locale.frontEnd.registration.statuses.isMilitary}
              </option>
              <option key='isStudent' value='isStudent'>
                {locale.frontEnd.registration.statuses.isStudent}
              </option>
            </Form.Select>
          </Col>
        </Row>
        {sessionStore.session?.user_role === 'manager' ? (
          <Row className='mt-3'>
            <Col>
              <Form.Check
                type='switch'
                key='createNews'
                name='createNews'
                label={locale.frontEnd.registration.mute}
                checked={mute === 1}
                onChange={(e) => setMute(e.target.checked ? 1 : 0)}
              />
            </Col>
          </Row>
        ) : (
          ''
        )}

        <Row className='mb-3 mt-3'>
          <Col>
            <Button onClick={() => handleProfile()} style={{ width: '100%', marginLeft: 0 }}>
              {locale.frontEnd.global.save}
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
});
export default Registration;
