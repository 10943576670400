import React, { useEffect } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import './style.scss';
import { useStores } from 'services/useStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ROUTES } from './App';
// eslint-disable-next-line import/no-relative-packages
import locale from '../../commands_ua';

const Main: React.FC = observer(() => {
  const history = useNavigate();
  const { api, sessionStore } = useStores();
  useEffect(() => {
    api.telegram.tg.BackButton.hide();
  }, []);

  return (
    <Container className='center-container'>
      <div className='main-block'>
        <Row>
          <Col>
            <h1>
              {sessionStore.session.user_role === 'manager' ? 'Admin' : locale.frontEnd.menu.hello}
            </h1>
          </Col>
        </Row>
        {sessionStore.session.user_role === 'user' ? (
          <Row>
            <Col>
              <Button style={{ width: 200 }} onClick={() => history(ROUTES.booking)}>
                {locale.frontEnd.menu.order}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row>
            <Col>
              <Button style={{ width: 200 }} onClick={() => history(ROUTES.adminView)}>
                {locale.frontEnd.menu.orderView}
              </Button>
            </Col>
          </Row>
        )}
        {sessionStore.session.user_role === 'user' ? (
          <Row>
            <Col>
              <Button style={{ width: 200 }} onClick={() => history(ROUTES.myBooking)}>
                {locale.frontEnd.menu.myOrder}
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {sessionStore.session.user_role === 'manager' || api.settings.app.showRules ? (
          <Row>
            <Col>
              <Button style={{ width: 200 }} onClick={() => history(ROUTES.rules)}>
                {locale.frontEnd.menu.rules}
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {sessionStore.session.user_role === 'manager' ? (
          <Row>
            <Col>
              <Button style={{ width: 200 }} onClick={() => history(ROUTES.settings)}>
                {locale.frontEnd.menu.settings}
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row>
          <Col>
            <Button style={{ width: 200 }} onClick={() => history(ROUTES.registration)}>
              {locale.frontEnd.menu.profile}
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
});
export default Main;
