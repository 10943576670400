// eslint-disable-next-line import/no-extraneous-dependencies
import { TelegramWebApps } from 'telegram-webapps';

export type TUseTelegram = {
  isTelegram: boolean;
  onClose: () => void;
  onToggleButton: () => void;
  tg: TelegramWebApps.WebApp;
  user: TelegramWebApps.WebAppUser;
  queryId: string;
};

// eslint-disable-next-line @typescript-eslint/dot-notation
const isTelegram: boolean = !!window['Telegram'];
const tg: TelegramWebApps.WebApp = (isTelegram ? Telegram.WebApp : {}) as TelegramWebApps.WebApp;

export function useTelegram() {
  const onClose = () => {
    if (!isTelegram) return;
    tg.close();
  };

  const onToggleButton = () => {
    if (!isTelegram) return;
    if (tg.MainButton.isVisible) {
      tg.MainButton.hide();
    } else {
      tg.MainButton.show();
    }
  };

  return {
    isTelegram: isTelegram && tg.initDataUnsafe?.user,
    onClose,
    onToggleButton,
    tg,
    user: isTelegram ? tg.initDataUnsafe?.user : {},
    queryId: isTelegram ? tg.initDataUnsafe?.query_id : '',
  } as TUseTelegram;
}
