class CommandsClass {
  locale = {
    // тут тексти для бекенда
    hello:
      'Вас вітає центр підготовки пілотів БпАК “SOLO WAY”\nЦе форма запису в комп`ютерний клас для самостійного навчання на ліцензійних симуляторах\n Для реєстрації та бронювання часу натисніть кнопку\n👇 ',
    setBookingToManagers: (
      //що отримує менеджер коди хтось забукав час
      userName: string,
      date: string,
      start: number,
      end: number,
      pc: number
    ) =>
      `🖥 Користувач <b>${userName}</b>\nстворив бронювання яке буде\n<b>${date}</b> з <b>${start}</b> по <b>${end}</b> години\nна компьютері <b>${pc}</b>.`,

    setBookingToUser: (
      //що отримує користувач коли забукав час
      userName: string,
      date: string,
      start: number,
      end: number,
      pc: number
    ) =>
      `Дякуємо, <b>${userName}</b>\nВи створили бронювання на\n<b>${date}</b> з <b>${start}</b> по <b>${end}</b> години\nна компьютері <b>${pc}</b>.\nЧекаємо на вас!`,

    deleteBookingToManager: (
      // що отримує менеджер коли хтось скасовує бронювання
      userName: string,
      date: string,
      start: number,
      end: number,
      pc: number
    ) =>
      `❌ Користувач <b>${userName}</b>\nскасував бронювання яке мало бути\n<b>${date}</b> з <b>${start}</b> по <b>${end}</b> години\nна компьютері <b>${pc}</b>.`,
  };

  frontEnd = {
    //тут тексти для фронтенда
    global: {
      // це загальні тексти
      booking: 'Забронювати час',
      bookingPrevAlert: 'Не можливо забронювати на минулу дату',
      date: 'Дата:',
      pc1: 'Пристрій: 🖥 ',
      pc2: 'пристрою',
      edit: 'Редагувати',
      approve: 'Підтвердити',
      hours: 'год',
      close: 'Закрити',
      save: 'Зберегти',
      orderSaved: 'Бронювання збережено',
      checkYourOrder: 'Перевірте ваше бронювання:',
      selectDate: 'Оберіть дату:',
      selectPCAndTime: 'Оберіть пристрій та час:',
      weekendDay: 'Вихідний день',
      nauDay: 'День для НАУ',

      orderInfoHeader: (order_pc: number, order_id: number) =>
        `🖥 ${order_pc} Бронювання №${order_id}`,
    },
    experience: {
      dron: 'Є досвід керування дроном',
      simulator: 'Є досвід в симуляторі',
      none: 'Не маю досвіду',
    },
    statuses: {
      isCivil: ' цивільний/а',
      isMilitary: 'військовий/а',
      isStudent: 'студент/ка НАУ, працівник НАУ',
    },
    orderStatus: {
      free: ' - вільно',
      bysy: ' - зайнято',
      selected: ' - обрано вами',
      your: ' - заброньовано вами',
    },
    menu: {
      //сторінка меню
      hello: 'Вітаємо',
      order: 'Забронювати час',
      orderView: 'Бронювання',
      myOrder: 'Мої бронювання',
      rules: 'Правила користування',
      settings: 'Налаштування',
      profile: 'Мій профіль',
    },
    myOrders: {
      //сторінка Мої бронювання
      header: 'Ваші бронювання:',
      emptyPage: 'Мабуть, ви ще нічого не бронювали',
      remove: 'Скасування бронювання',
      removeAsk: (date: string, start: number, end: number) =>
        `Ви дійсно бажаєте скасувати ваше бронювання ${date} з ${start}:00 по ${end}:00 ?`,
    },
    registration: {
      //сторінка реєстрації
      header: 'Вкажіть ваші дані',
      header2: 'Ваші дані',
      email: 'Email',
      emailBysy: 'Цей email вже хтось використовує',
      phone: 'Телефон',
      name: 'ПІБ',
      mute: 'Не отримувати сповіщення',
      experience: {
        title: 'Чи маєте досвід керування дронами',
        dron: 'Є досвід керування дроном',
        simulator: 'Є досвід в симуляторі',
        none: 'Досвіду нема',
      },
      statuses: {
        title: 'Ваш статус',
        isCivil: ' цивільний/а',
        isMilitary: 'військовий/а',
        isStudent: 'студент/ка НАУ, працівник НАУ',
      },
    },
  };
}
export default new CommandsClass();
