import { action, makeObservable, observable } from 'mobx';
import { RootStore } from './RootStore';

export type TOrderItem = {
  order_id: number;
  user_id: number;
  order_date: string;
  order_pc: number;
  order_start: number;
  order_end: number;
  order_type: string;
  order_old: number;
  user_name?: string;
  user_phone?: string;
  user_email?: string;
  user_tg_name?: string;
  user_data?: string;
};

class BookingStore {
  _data!: TOrderItem[];
  rootStore: RootStore;
  idKey: string = 'order_id';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this._data = [];
    makeObservable(this, {
      _data: observable,
      update: action,
      set: action,
      add: action,
      remove: action,
    });
  }

  get data(): TOrderItem[] {
    return this._data as TOrderItem[];
  }

  set(newData: TOrderItem[]): void {
    this._data = newData;
  }

  add(newData: TOrderItem): void {
    if (Array.isArray(newData)) this._data = [...this._data, ...newData];
    else this._data.push(newData);
  }
  get(id: number): TOrderItem | undefined {
    if (!this._data?.length) return undefined;
    return this._data.find((element) => id === element[this.idKey]);
  }
  getByDate(date: string): TOrderItem[] {
    if (!this._data?.length) return [];
    return this._data.filter((element: TOrderItem) => date === element.order_date);
  }
  update(newData: TOrderItem[]): void {
    const normalizedData = Array.isArray(newData) ? newData : [newData];
    this._data = this._data?.length
      ? this._data.map((element) => {
          if (newData[this.idKey] === element[this.idKey]) return { ...element, ...newData };
          return element;
        })
      : [...normalizedData];
    normalizedData.forEach((element) => {
      const index = this._data.findIndex((value) => value[this.idKey] === element[this.idKey]);
      if (index < 0) this._data.push(element);
    });
  }
  remove(id: number): void {
    const index = this._data.findIndex((element) => element[this.idKey] === id);
    if (index !== -1) this._data.splice(index, 1);
  }
}

export default BookingStore;
