import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Form, Button, Spinner, Modal } from 'react-bootstrap';
import './style.scss';
import { TUseTelegram, useTelegram } from 'services/useTelegram';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useStores } from 'services/useStore';
import { TOrderItem } from 'stores/BookingStore';
import { observer } from 'mobx-react';
import { ROUTES } from './App';
// eslint-disable-next-line import/no-relative-packages
import locale from '../../commands_ua';

type TPlace = number[];
type TOrderMap = TPlace[];
type TOrdered = { PC: number; start: number; end: number };
export type TBook = {
  user_id: number;
  user_tg: number;
  date: string;
  orderList: TOrdered[];
};

const AdminView: React.FC = observer(() => {
  const { api, sessionStore, booking } = useStores();
  const history = useNavigate();
  const telegram = useTelegram() as TUseTelegram;
  const [loaded, setLoaded] = useState(false);
  const [orderInfo, setOrderInfo] = useState<TOrderItem | false>();
  const [startTime, setStartTime] = useState<number>(10);
  const [endTime, setEndTime] = useState<number>(18);
  const [orderMap, setOrderMap] = useState<TOrderMap>();
  const [orderDate, setOrderDate] = useState<string>();
  const [pc, setPC] = useState<string>('');
  const backButton = telegram.tg.BackButton;
  const PCcount = +api.settings.app.PCcount || 5;
  const { workTime } = api.settings.app;

  const clearData = () => {
    const orderMapCopy = [];
    const line = [];
    for (let i = startTime; i < endTime + 1; i++) line.push(0);
    for (let j = 1; j < PCcount + 1; j++) orderMapCopy.push([...line]);
    return orderMapCopy;
  };

  const buildBysy = (date: string) => {
    const clear = clearData();
    const currentOrderMap = [...clear];
    booking.data.forEach((order: TOrderItem) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { order_date, order_pc, order_start, order_end, order_id } = order;
      if (order_date !== date) return;
      const during = order_end - order_start;
      for (let i = 0; i < during; i++) {
        currentOrderMap[order_pc - 1][order_start + i - startTime] = order_id;
      }
    });
    setOrderMap(currentOrderMap);
  };

  const checkBysy = async () => {
    if (!orderDate) {
      return;
    }
    api.getOrders(orderDate).then(() => {
      if (orderDate) buildBysy(orderDate);
      setLoaded(true);
    });
  };

  const show = (time: number, place: number) => {
    if (!orderMap) return;
    const order = orderMap[place - 1][time - startTime];
    if (!order) return;
    const orderData = booking.get(order);
    setOrderInfo(orderData);
  };

  const hideModal = () => setOrderInfo(false);

  const setDate = (date: string) => {
    const weekDay = moment(date).isoWeekday();
    const [start, end] = workTime[weekDay - 1];
    setStartTime(+start);
    setEndTime(+end);
    setOrderDate(date);
  };

  useEffect(() => {
    if (sessionStore.session?.user_role !== 'manager') {
      history(ROUTES.booking);
      return;
    }
    setOrderDate(moment().format('YYYY-MM-DD') + '');
    setDate(moment().format('YYYY-MM-DD') + '');
    const pcList = [];
    for (let i = 1; i < PCcount + 1; i++) pcList.push(i);
    setPC(pcList.join(','));
    backButton.show();
    backButton.onClick(() => {
      history(ROUTES.home);
    });
  }, []);

  useEffect(() => {
    setLoaded(false);
    checkBysy();
  }, [orderDate]);

  useEffect(() => {
    if (!orderMap) return;
    const ordered = [];
    for (let i = 1; i < PCcount + 1; i++) {
      let orderThis = { PC: i, start: 0, end: 0 };
      for (let h = startTime; h < endTime + 2; h++) {
        if (orderMap[i - 1][h - startTime] === 1) {
          if (!orderThis.start) {
            orderThis.start = h;
          } else orderThis.end = h;
        } else if (orderThis.start) {
          orderThis.end = h;
          ordered.push({ ...orderThis });
          orderThis = { PC: i, start: 0, end: 0 };
        }
      }
    }
  }, [orderMap]);

  const buttonClassName = (time: number, place: number) => {
    if (!orderMap) return '';
    return orderMap[place - 1][time - startTime] !== 0 ? 'active' : '';
  };

  const showExperience = (exp: string) => {
    switch (exp) {
      case 'dron':
        return <div>{locale.frontEnd.experience.dron}</div>;
      case 'simulator':
        return <div>{locale.frontEnd.experience.simulator}</div>;
      default:
        return <div>{locale.frontEnd.experience.none}</div>;
    }
  };

  const showUserInfo = (userInfo: TOrderItem) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let userData: any = {};
    try {
      if (userInfo.user_data) userData = JSON.parse(userInfo.user_data.replaceAll("'", '"'));
    } catch (e) {
      //
    }
    return (
      <div>
        {userInfo.user_name}, {userInfo.user_phone}, @{userInfo.user_tg_name}
        <br />
        {userData?.experience ? showExperience(userData?.experience) : ''}
        {userData?.status === 'isCivil' ? <div>{locale.frontEnd.statuses.isCivil}</div> : ''}
        {userData?.status === 'isMilitary' ? <div>{locale.frontEnd.statuses.isMilitary}</div> : ''}
        {userData?.status === 'isStudent' ? <div>{locale.frontEnd.statuses.isStudent}</div> : ''}
      </div>
    );
  };

  const build = () => {
    const rows = [];
    if (!startTime && !endTime) {
      return <h3 style={{ textAlign: 'center' }}>{locale.frontEnd.global.weekendDay}</h3>;
    }
    for (let i = startTime; i < endTime + 1; i++) {
      const cols = [
        <div className='time-header' key={'timeHeader' + i}>
          {i < 10 ? 0 : ''}
          {i}.00
        </div>,
      ];
      for (let j = 1; j < PCcount + 1; j++) {
        cols.push(
          <Button key={`${j}${i}`} onClick={() => show(i, j)} className={buttonClassName(i, j)}>
            {' '}
          </Button>
        );
      }
      rows.push(cols);
    }
    return rows.map((i, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={index} className='order-line'>
        {i}
      </div>
    ));
  };

  if (!loaded) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: '50% auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
        </div>
      </div>
    );
  }

  return (
    <>
      <Container className='mt-2'>
        <Row>
          <Col style={{ display: 'flex', justifyContent: 'center' }}>
            <Form.Control
              value={orderDate}
              onChange={(e) => setDate(e.target.value)}
              type='date'
              required
              size='sm'
              style={{ width: '55%' }}
            />
          </Col>
        </Row>
        {orderDate ? (
          <>
            <Row>
              <Col style={{ textAlign: 'center' }} className='mt-2'>
                {moment(orderDate)?.format('Do MMMM, dddd')}
              </Col>
            </Row>
            <Row>
              <Col className='time'>
                <div className='time-body'>
                  <div className='header'>
                    <div className='empty-header'>
                      №<br />
                      {locale.frontEnd.global.pc2}
                    </div>
                    {pc.split(',').map((i) => (
                      <div className='place-header' key={'place' + i}>
                        🖥 {i}
                      </div>
                    ))}
                  </div>
                  {build()}
                </div>
              </Col>
            </Row>
          </>
        ) : (
          ''
        )}
      </Container>
      <Modal show={!!orderInfo} size='sm' key='editModal' centered onHide={hideModal}>
        {orderInfo ? (
          <>
            <Modal.Header closeButton>
              <h3>
                {locale.frontEnd.global.orderInfoHeader(orderInfo.order_pc, orderInfo.order_id)}
              </h3>
            </Modal.Header>
            <Modal.Body>
              <h5>
                {moment(orderInfo.order_date)?.format('Do MMMM, dddd')} {orderInfo.order_start}
                {' - '}
                {orderInfo.order_end}:00
              </h5>
              {showUserInfo(orderInfo)}
            </Modal.Body>
          </>
        ) : (
          ''
        )}
      </Modal>
    </>
  );
});
export default AdminView;
