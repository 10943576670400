import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Spinner } from 'react-bootstrap';
import Main from './Main';
import Registration from './Registration';
import Booking from './Booking';
import Orders from './Orders';
import Rules from './Rules';
import Settings from './Settings';
import AdminView from './AdminView';

export enum ROUTES {
  home = '/',
  registration = '/registration',
  booking = '/booking',
  myBooking = '/myBooking',
  rules = '/rules',
  settings = '/settings',
  adminView = '/adminView',
}

const App: React.FC = observer(() => {
  const history = useNavigate();
  const { api } = useStores();
  const [inited, setInit] = useState(false);

  useEffect(() => {
    api.telegram.tg.expand();
    Promise.all([api.getSettings()]).then(() => {
      if (window.location.pathname !== ROUTES.registration) {
        api.getSession().then((result: boolean) => {
          if (!result) {
            history(ROUTES.registration);
            setInit(true);
          } else setInit(true);
        });
      } else setInit(true);
    });
  }, []);

  if (!inited) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <div style={{ margin: '50% auto', display: 'flex' }}>
          <Spinner animation='border' role='status' />
        </div>
      </div>
    );
  }

  return (
    <Routes>
      <Route path={ROUTES.home} element={<Main />} />
      <Route path={ROUTES.registration} element={<Registration />} />
      <Route path={ROUTES.booking} element={<Booking />} />
      <Route path={ROUTES.myBooking} element={<Orders />} />
      <Route path={ROUTES.rules} element={<Rules />} />
      <Route path={ROUTES.settings} element={<Settings />} />
      <Route path={ROUTES.adminView} element={<AdminView />} />
    </Routes>
  );
});

export default App;
