import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button, Modal, Alert } from 'react-bootstrap';
import './style.scss';
import { useStores } from 'services/useStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { TOrderItem } from 'stores/BookingStore';
import moment from 'moment';
import { ROUTES } from './App';
// eslint-disable-next-line import/no-relative-packages
import locale from '../../commands_ua';

const Orders: React.FC = observer(() => {
  const history = useNavigate();
  const { api, booking } = useStores();
  const [removeAsk, setRemoveAsk] = useState<string>('');
  const [orders, setOrders] = useState<TOrderItem[]>();
  const [removeId, setRemoveId] = useState(0);
  const backButton = api.telegram.tg.BackButton;
  const remove = (order: TOrderItem) => {
    setRemoveId(order.order_id);
    setRemoveAsk(
      locale.frontEnd.myOrders.removeAsk(
        moment(order.order_date).format('Do MMMM, dddd'),
        order.order_start,
        order.order_end
      )
    );
  };
  const hideModal = () => {
    setRemoveAsk('');
    setRemoveId(0);
  };
  const rebuild = () => {
    api.getAllOrders().then((data: TOrderItem[]) => {
      setOrders(data);
    });
  };
  const removeOrder = () => {
    if (removeId) {
      api.removeOrder(removeId).then(() => {
        booking.remove(removeId);
        rebuild();
      });
    }
    hideModal();
  };
  useEffect(() => {
    backButton.show();
    backButton.onClick(() => {
      history(ROUTES.home);
    });
    rebuild();
  }, []);

  return (
    <>
      <Container className='center-container2 mt-3'>
        <div className='main-block' style={{ width: '90%' }}>
          <Row>
            <Col>
              <h1>{locale.frontEnd.myOrders.header}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              {!orders?.length ? (
                <Alert variant='warning'>{locale.frontEnd.myOrders.emptyPage}</Alert>
              ) : (
                ''
              )}
              {orders?.map((order: TOrderItem, index: number) => (
                <Row key={order.order_id} className={'table-row ' + (index % 2 ? ' odd' : '')}>
                  <Col style={{ width: '40%' }}>
                    {moment(order.order_date).format('Do MMMM, dddd')}
                  </Col>
                  <Col style={{ textAlign: 'right' }}>🖥 {order.order_pc}</Col>
                  <Col>
                    {order.order_start} - {order.order_end}:00
                  </Col>
                  <Col style={{ textAlign: 'right', paddingRight: 20 }}>
                    {order.order_end - order.order_start}
                    {locale.frontEnd.global.hours}
                  </Col>
                  <Col style={{ width: 40, textAlign: 'right' }}>
                    {order.order_old ? (
                      ''
                    ) : (
                      <Button variant='danger' size='sm' onClick={() => remove(order)}>
                        ❌
                      </Button>
                    )}
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
      </Container>
      <Modal show={!!removeAsk} size='sm' key='editModal' centered onHide={hideModal}>
        <Modal.Header closeButton>
          <h3>{locale.frontEnd.myOrders.remove}:</h3>
        </Modal.Header>
        <Modal.Body>
          <Alert variant='warning'>{removeAsk}</Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={hideModal}>
            {locale.frontEnd.global.close}
          </Button>
          <Button variant='danger' onClick={() => removeOrder()}>
            {locale.frontEnd.myOrders.remove}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});
export default Orders;
