// eslint-disable-next-line import/no-extraneous-dependencies
import { makeAutoObservable } from 'mobx';
import Api from '../services/Api';
import SessionStore from './SessionStore';
import BookingStore from './BookingStore';

export interface IAppStore {
  api: Api;
}

export class RootStore implements IAppStore {
  sessionStore = new SessionStore(this);
  api = new Api(this);
  booking = new BookingStore(this);
  constructor() {
    makeAutoObservable(this);
  }
}
