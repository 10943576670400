/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Container, Button } from 'react-bootstrap';
import './style.scss';
import { useStores } from 'services/useStore';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router';
import { ROUTES } from './App';
// eslint-disable-next-line import/no-relative-packages
import locale from '../../commands_ua';

const Rules: React.FC = observer(() => {
  const history = useNavigate();
  const { api, sessionStore } = useStores();
  const [text, setText] = useState('');
  const [mode, setMode] = useState('show');
  const backButton = api.telegram.tg.BackButton;
  const save = () => {
    api.setRules(text).then(() => {
      history(ROUTES.home);
    });
  };
  useEffect(() => {
    backButton.show();
    backButton.onClick(() => {
      history(ROUTES.home);
    });
    api.getRules().then((data: string) => setText(data));
  }, []);

  return (
    <Container className='editable'>
      {sessionStore.session.user_role === 'manager' ? (
        <>
          {mode === 'show' ? (
            <Button className='rule-controll' onClick={() => setMode('edit')}>
              ✏️
            </Button>
          ) : (
            ''
          )}
          {mode === 'edit' ? (
            <Button className='rule-controll' onClick={() => setMode('show')}>
              👁
            </Button>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      {mode === 'show' ? <p dangerouslySetInnerHTML={{ __html: text }} /> : ''}
      {mode === 'edit' ? (
        <>
          <textarea value={text} onChange={(e) => setText(e.target.value)} />
          <Button onClick={save}>{locale.frontEnd.global.save}</Button>
        </>
      ) : (
        ''
      )}
    </Container>
  );
});
export default Rules;
