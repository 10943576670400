import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from 'components/App/App';
import { RootStore } from 'stores/RootStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StoreProvider } from 'stores/StoreContext';
import TestSupport from 'services/test';
import GlobalThis from './global';
import './index.scss';
import packageInfo from '../package.json';

declare const global: GlobalThis;
console.log(
  '%cVersion: ' + packageInfo.version.replaceAll(' ', '.'),
  'font-size: 20px; color: green;'
);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const store = new RootStore();
root.render(
  <Router>
    <StoreProvider store={store}>
      <App />
    </StoreProvider>
  </Router>
);
global.TEST = new TestSupport(store);
